import { MixType, RefType } from '@scandi-src/src/type/Common.type';
import {
    EventsType,
    FieldAttrType,
    FieldOptionsType,
    LabelType,
    ValidationRuleType
} from '@scandi-src/src/type/Field.type';
import PropTypes from 'prop-types';

import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

import Field from './Field.component';
import { FIELD_TYPE } from './Field.config';
/**
 * @namespace Scandipwa/Component/Field/Container/FieldContainer
 */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        // Field attributes
        type: PropTypes.oneOf(Object.values(FIELD_TYPE)),
        attr: FieldAttrType,
        events: EventsType,
        isDisabled: PropTypes.bool,
        mix: MixType,
        options: FieldOptionsType,
        elemRef: RefType,
        changeValueOnDoubleClick: PropTypes.bool,

        // Validation
        validationRule: ValidationRuleType,
        validateOn: PropTypes.arrayOf(PropTypes.string),
        showErrorAsLabel: PropTypes.bool,

        // Labels
        label: LabelType,
        subLabel: PropTypes.string,
        addRequiredTag: PropTypes.bool,
        isToggleButtonDisplayed: PropTypes.bool
    };

    static defaultProps = {
        type: FIELD_TYPE.text,
        attr: {},
        events: {},
        mix: {},
        validationRule: {},
        validateOn: [],
        options: [],
        showErrorAsLabel: true,
        isDisabled: false,
        addRequiredTag: false,
        label: '',
        subLabel: '',
        elemRef: null,
        changeValueOnDoubleClick: false,
        isToggleButtonDisplayed: false
    };

    state = {
        validationResponse: null,
        inputPasswordType: FIELD_TYPE.password,
        toggleButtonText: __('Show')
    };

    containerFunctions = {
        validate: this.validate.bind(this),
        togglePasswordType: this.togglePasswordType.bind(this)
    };

    togglePasswordType() {
        switch (this.state.inputPasswordType) {
        case FIELD_TYPE.password:
            this.setState({ inputPasswordType: FIELD_TYPE.text });
            this.setState({ toggleButtonText: __('Hide') });
            break;
        case FIELD_TYPE.text:
            this.setState({ inputPasswordType: FIELD_TYPE.password });
            this.setState({ toggleButtonText: __('Show') });
            break;
        default:
            this.setState({ inputPasswordType: FIELD_TYPE.password });
            this.setState({ toggleButtonText: __('Show') });
            break;
        }
    }

    containerProps() {
        const {
            events,
            validateOn,
            type,
            attr: {
                autoComplete,
                autocomplete,
                ...attr
            } = {},
            isDisabled,
            mix,
            options,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            changeValueOnDoubleClick,
            isToggleButtonDisplayed
        } = this.props;
        const { validationResponse, inputPasswordType, toggleButtonText } = this.state;
        const { validate } = this.containerFunctions;

        // Surrounds events with validation
        const newEvents = { ...events };
        validateOn.forEach((eventName) => {
            const { [eventName]: baseEvent } = events;
            newEvents[eventName] = baseEvent ? this.validateOnEvent.bind(this, baseEvent) : validate;
        });

        return {
            type,
            attr: {
                ...attr,
                autoComplete: autoComplete || autocomplete
            },
            isDisabled,
            mix,
            options,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            changeValueOnDoubleClick,
            validationResponse,
            events: newEvents,
            inputPasswordType,
            isToggleButtonDisplayed,
            toggleButtonText,
            fieldRef: this.fieldRef,
            setRef: this.setRef.bind(this)
        };
    }

    render() {
        return (
            <Field
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default FieldContainer;
