/* eslint-disable no-unused-vars */
import { RESET_SAMECOLORS, SET_SAMECOLORS } from './ProductSameColors.action';

/** @namespace Scandipwa/Store/ProductSameColors/Reducer/getInitialState */
export const getInitialState = () => ({
    payload: {}
});

/** @namespace Scandipwa/Store/ProductSameColors/Reducer/ProductSameColorsReducer */
export const ProductSameColorsReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case SET_SAMECOLORS:
        const { payload } = action;

        return {
            ...state,
            payload
        };
    case RESET_SAMECOLORS:
        const { payload: garbage, ...otherState } = state;

        return {
            payload: {},
            ...otherState
        };

    default:
        return state;
    }
};

export default ProductSameColorsReducer;
