/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import CheckoutQuery from 'Query/Checkout.query';
import { Field } from 'Util/Query';

import { getGuestQuoteId } from '../../scandi-src/src/util/Cart';

/** @namespace Scandipwa/Query/Adyen/Query/AdyenQuery */
export class AdyenQuery {
    getSession() {
        return new Field('getAdyenSession')
            .addField('sessionData')
            .addField('id')
            .addField('clientKey')
            .addField('returnUrl');
    }

    getPaymentDetails(input) {
        return new Field('adyenPaymentDetails')
            .addArgument('payload', 'String!', JSON.stringify(input))
            .addArgument('cart_id', 'String!', getGuestQuoteId())
            .addFieldList(['isFinal', 'resultCode', 'additionalData', 'action']);
    }

    getConfig() {
        return new Field('getAdyenConfig')
            .addField('originKey')
            .addField('mode')
            .addField('locale');
    }

    getPaymentMethods() {
        const guestQuoteId = getGuestQuoteId();
        const getAdyenPaymentMethods = new Field('getAdyenPaymentMethods')
            .addField('type')
            .addField('brands')
            .addField(new Field('details')
                .addField('key')
                .addField('type')
                .addField('optional'));

        CheckoutQuery._addGuestCartId(guestQuoteId, getAdyenPaymentMethods);

        return getAdyenPaymentMethods;
    }
}

export default new AdyenQuery();
