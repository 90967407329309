/* eslint-disable */

import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchMutation } from 'Util/Request';

import AdyenQuery from '../../query/Adyen.query';
import { updateAdyenState, updateAdyenStatus, updateAdyenDetails } from './Adyen.action';

export const PAYMENT_TOTALS = 'PAYMENT_TOTALS';
export const STRIPE_AUTH_REQUIRED = 'Authentication Required: ';

/** @namespace Scandipwa/Store/Adyen/Dispatcher/AdyenDispatcher */
export class AdyenDispatcher {
    async getPaymentDetails(dispatch, payload) {
        const { orderId = '' } = payload;

        if (!orderId) {
            payload.orderId = BrowserDatabase.getItem('orderId');
        }

        const query = AdyenQuery.getPaymentDetails(payload);

        try {
            const {
                adyenPaymentDetails: {
                    isFinal,
                    resultCode,
                    additionalData,
                    action
                }
            } = await fetchMutation(query);

            const data = {
                isFinal,
                resultCode,
                additionalData,
                action
            };

            dispatch(updateAdyenDetails(data));
        } catch (e) {
            this._handleError(dispatch, e);

            // On 3D security, after order is placed, Cart data being removed from FE. To do not change full data
            // flow, we just request once mor cart data.
            // This function called 2 times - before 3D popup and after. our Goal - catch 2ond time call, and on error
            // reload cart data.
            CartDispatcher.updateInitialCartData(dispatch);
        }
    }

    _handleError(dispatch, error) {
        const [{ message, debugMessage }] = error;

        dispatch(updateAdyenStatus({
            isLoading: false,
            isPaymentMethodsLoading: false,
            isDeliveryOptionsLoading: false
        }));
        dispatch(showNotification('error', debugMessage || message));
    }

    updateAdyenState(dispatch, payload) {
        dispatch(updateAdyenState(payload));
    }
}

export default new AdyenDispatcher();
