Mosaic.setPlugins([require('./plugin/App.plugin.js'),
require('./plugin/Checkout.state.plugin.js'),
require('./plugin/CheckoutBilling.plugin.js'),
require('./plugin/CheckoutBillingContainer.plugin.js'),
require('./plugin/CheckoutContainer.plugin.js'),
require('./plugin/CheckoutPayment.plugin.js'),
require('./plugin/CheckoutPayments.plugin.js'),
require('./plugin/CheckoutPaymentsContainer.plugin.js'),
require('./plugin/CheckoutQuery.plugin.js'),
require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js')]);
/* eslint-disable */
import 'Util/Polyfill';
import 'Style/main';

import { render } from 'react-dom';

import App from 'Component/App';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: process.env.GTM
}

TagManager.initialize(tagManagerArgs)

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

render(<App />, document.getElementById('root'));
