import './FieldNumber.sytle.scss';

import { FIELD_TYPE } from 'Component/Field/Field.config';
import { FieldNumber as SourceFieldNumber } from 'SourceComponent/FieldNumber/FieldNumber.component';

import { DEFAULT_MAX_PRODUCTS } from '../../../scandi-src/src/util/Product/Extract';

/** @namespace Scandipwa/Component/FieldNumber/Component/FieldNumber */
export class FieldNumber extends SourceFieldNumber {
    render() {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS },
            events,
            setRef,
            value,
            handleValueChange,
            isDisabled
        } = this.props;

        return (
            <>
                <button
                  disabled={ +value === min || isDisabled }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleValueChange(+value - 1) }
                  aria-label={ __('Subtract') }
                  type={ FIELD_TYPE.button }
                  className="Qty-Remove"
                />
                <input
                  ref={ (elem) => setRef(elem) }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events }
                  type={ FIELD_TYPE.number }
                  readOnly
                  aria-label={ __('Value') }
                  value={ value }
                  disabled={ isDisabled }
                />
                <button
                  disabled={ +value === max || isDisabled }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleValueChange(+value + 1) }
                  aria-label={ __('Add') }
                  type={ FIELD_TYPE.button }
                  className="Qty-Add"
                />
            </>
        );
    }
}

export default FieldNumber;
