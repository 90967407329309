/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import { DirectionType } from 'Type/Direction.type';

import { RIGHT } from './ChevronIcon.config';

import './ChevronIcon.style';

/** @namespace Scandipwa/Component/ChevronIcon/Component/ChevronIconComponent */
export class ChevronIconComponent extends PureComponent {
    static propTypes = {
        direction: DirectionType
    };

    static defaultProps = {
        direction: RIGHT
    };

    render() {
        const { direction } = this.props;

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M 5.8535 13.707 L 11.5605 8 L 5.8535 2.293 L 6 3 L 11 8 L 6 13 L 5.8535 13.707 Z" />
            </svg>
        );
    }
}

export default ChevronIconComponent;
