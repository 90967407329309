import { GET_RELATED_PRODUCTS, GET_RELATED_PRODUCTS_DATA } from './RelatedProducts.action';

/** @namespace Scandipwa/Store/RelatedProducts/Reducer/getInitialState */
export const getInitialState = () => ({
    products: {}
});

/** @namespace Scandipwa/Store/RelatedProducts/Reducer/RelatedProductsReducer */
export const RelatedProductsReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case GET_RELATED_PRODUCTS:
        const { products } = action;
        return {
            ...state,
            products
        };
    case GET_RELATED_PRODUCTS_DATA:
        const { payload: { blockInfo, productData: { products: tmpProducts } } } = action;
        const { productsData: prevProductsData = {} } = state;
        const { group_id, title } = blockInfo;

        const productsData = {
            ...prevProductsData,
            [group_id]: { title, products: tmpProducts.items }
        };

        return {
            ...state,
            productsData
        };
    default:
        return state;
    }
};

export default RelatedProductsReducer;
