import { GET_ACM_CONTENTS } from 'Store/ACM/ACM.action';

/** @namespace Scandipwa/Store/ACM/Reducer/getInitialState */
export const getInitialState = () => ({
    acm: {}
});

/** @namespace Scandipwa/Store/ACM/Reducer/ACMReducer */
export const ACMReducer = (
    state = getInitialState(),
    action,
) => {
    switch (action.type) {
    case GET_ACM_CONTENTS:
        const { acm } = action;

        return {
            ...state,
            isLoading: false,
            acm
        };

    default:
        return state;
    }
};

export default ACMReducer;
