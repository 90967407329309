// TODO rename
export const SET_SAMECOLORS = 'SET_SAMECOLORS';
export const RESET_SAMECOLORS = 'RESET_SAMECOLORS';

// TODO rename
/** @namespace Scandipwa/Store/ProductSameColors/Action/setSameColors */
export const setSameColors = (payload) => ({
    type: SET_SAMECOLORS,
    payload
});

/** @namespace Scandipwa/Store/ProductSameColors/Action/resetSameColors */
export const resetSameColors = () => ({
    type: RESET_SAMECOLORS
});
