import {
    UPDATE_CONFIG as SOURCE_UPDATE_CONFIG,
    UPDATE_CONFIG_DEVICE as SOURCE_UPDATE_CONFIG_DEVICE,
    updateConfig as sourceUpdateConfig,
    updateConfigDevice as sourceUpdateConfigDevice
} from 'SourceStore/Config/Config.action';

// TODO: implement UPDATE_CONFIG
export const UPDATE_CONFIG = SOURCE_UPDATE_CONFIG;
export const CONFIG_ERROR = 'config_error';

// TODO: implement updateConfig
export const updateConfig = sourceUpdateConfig;

// TODO: implement UPDATE_CONFIG_DEVICE
export const UPDATE_CONFIG_DEVICE = SOURCE_UPDATE_CONFIG_DEVICE;

// TODO: implement updateConfigDevice
export const updateConfigDevice = sourceUpdateConfigDevice;

/** @namespace Scandipwa/Store/Config/Action/configError */
export const configError = () => ({
    type: CONFIG_ERROR
});
