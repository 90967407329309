import { GET_STORE_LOCATOR_DATA, GET_STORE_LOCATOR_RESULT } from './StoreLocator.action';

/** @namespace Scandipwa/Store/StoreLocator/Reducer/getInitialState */
export const getInitialState = () => ({
    stores: {}
});

/** @namespace Scandipwa/Store/StoreLocator/Reducer/StoreLocatorReducer */
export const StoreLocatorReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case GET_STORE_LOCATOR_DATA:
        const { stores } = action;
        return {
            ...state,
            stores
        };
    case GET_STORE_LOCATOR_RESULT:
        const { storeResult } = action;
        return {
            ...state,
            storeResult
        };
    default:
        return state;
    }
};

export default StoreLocatorReducer;
