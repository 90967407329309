/* eslint-disable */

import { fetchQuery } from 'Util/Request';

import { CREDIT_CARD_TYPE } from '../component/Adyen/Adyen.config';
import AdyenQuery from '../query/Adyen.query';
import { ADYEN_CC } from './CheckoutPayments.plugin';

export class CheckoutPaymentsContainerPlugin {
    state = {
        paymentMethodConfig: {},
        paymentMethodData: {},
        adyenPaymentMethods: {},
        isAdyenLoaded: false
    };

    // eslint-disable-next-line no-unused-vars
    aroundCollectAdditionalData = (args, callback = () => {}, instance) => {
        const { selectedPaymentCode } = instance.state;
        const additionalDataGetter = instance.dataMap[selectedPaymentCode];

        if (!additionalDataGetter) {
            return {};
        }

        return additionalDataGetter(instance);
    };

    aroundComponentDidMount = (args, callback = () => {}, instance) => {
        this.initializeAdyen().then(
            /** @namespace ScandiPWA/AdyenGraphQl/Plugin/Plugin/initializeAdyenThen */
            (result) => {
                console.log(result);
                this.setPaymentMethodConfig(result.paymentMethodConfig, instance);
                this.setAdyenPaymentMethods({ adyenPaymentMethods: result.adyenPaymentMethods }, instance);
                instance.setState({ isAdyenLoaded: true });
            }
        );

        callback.apply(instance, args);
    };

    aroundDataMap = (originalMember) => ({
        ...originalMember,
        [ADYEN_CC]: this.getAdyenData.bind(this)
    });

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        setPaymentMethodData(data) {
            const { selectedPaymentCode } = instance.state;
            instance.setState(({ paymentMethodData }) => ({
                paymentMethodData: {
                    ...paymentMethodData,
                    [selectedPaymentCode]: data
                }
            }));
        }
    });

    getAdyenData(instance) {
        const { paymentMethodData: { [ADYEN_CC]: asyncData = {} } } = instance.state;

        return { asyncData };
    }

    setPaymentMethodConfig(paymentMethodConfig, instance) {
        instance.setState({ paymentMethodConfig });
    }

    setAdyenPaymentMethods(adyenPaymentMethods, instance) {
        instance.setState(adyenPaymentMethods);
    }

    initializeAdyen = async () => {
        const queries = [
            AdyenQuery.getConfig(),
            AdyenQuery.getPaymentMethods()
        ];

        console.log('initializeAdyen');

        const { getAdyenConfig } = await fetchQuery(queries);
        let { getAdyenPaymentMethods } = await fetchQuery(queries);

        const paymentMethod = getAdyenPaymentMethods.find(({ type }) => type === CREDIT_CARD_TYPE) || {};

        const {
            originKey,
            mode: environment,
            locale
        } = getAdyenConfig || {};

        const script = document.createElement('script');
        script.setAttribute(
            'src',
            `https://checkoutshopper-${ environment }.adyen.com/checkoutshopper/sdk/5.15.0/adyen.js`
        );
        script.setAttribute('crossorigin', 'anonymous');
        script.addEventListener('load', () => {
            const { AdyenCheckout } = window;

            window.adyen = new AdyenCheckout({
                locale,
                environment,
                originKey
            });
        });

        const link = document.createElement('link');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute(
            'href',
            `https://checkoutshopper-${ environment }.adyen.com/checkoutshopper/sdk/5.15.0/adyen.css`
        );
        link.setAttribute('crossorigin', 'anonymous');

        document.body.prepend(script);
        document.head.append(link);

        return { paymentMethodConfig: { paymentMethod }, adyenPaymentMethods: getAdyenPaymentMethods };
    };

    aroundContainerProps = (args, callback = () => {}, instance) => {

        console.log(instance.state.adyenPaymentMethods);

        return {
            ...callback.apply(instance, args),
            setPaymentDetailData: instance.props.setPaymentDetailData,
            paymentMethodConfig: instance.state.paymentMethodConfig,
            adyenPaymentMethods: instance.state.adyenPaymentMethods,
            isAdyenLoaded: instance.state.isAdyenLoaded
        };
    };
}

const {
    aroundCollectAdditionalData,
    aroundDataMap,
    aroundComponentDidMount,
    containerFunctions,
    aroundContainerProps
} = new CheckoutPaymentsContainerPlugin();

export const config = {
    'Scandipwa/Component/CheckoutPayments/Container/CheckoutPaymentsContainer': {
        'member-property': {
            dataMap: aroundDataMap,
            containerFunctions
        },
        'member-function': {
            componentDidMount: aroundComponentDidMount,
            collectAdditionalData: aroundCollectAdditionalData,
            containerProps: aroundContainerProps
        }
    }
};

export default config;
