/* eslint-disable */
import getStore from 'Util/Store';

import { AdyenReducer } from '../store/Adyen/Adyen.reducer';

class AppPlugin {
    aroundConfigureStore = (args, callback, instance) => {
        callback.apply(instance, args);
        getStore().injectReducer('AdyenReducer', AdyenReducer);
    };
}

const { aroundConfigureStore } = new AppPlugin();

const config = {
    'Component/App/Component': {
        'member-function': {
            configureStore: [
                {
                    position: 100,
                    implementation: aroundConfigureStore
                }
            ]
        }
    }
};

export default config;
