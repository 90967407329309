/* eslint-disable */

import { CART_TAB } from 'Component/NavigationTabs/NavigationTabs.config';
import { DETAILS_STEP, PAYMENT_TOTALS } from 'Route/Checkout/Checkout.config';
import { GUEST_QUOTE_ID } from 'Util/Cart/Token';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';

import { ADYEN_CC } from './CheckoutPayments.plugin';
import { getGuestQuoteId } from "../../scandi-src/src/util/Cart";

import { getGraphqlEndpoint, parseResponse, postFetch } from "../../scandi-src/src/util/Request";

export class CheckoutContainerPlugin {
    aroundComponentDidUpdate = (args, callback = () => {}, instance) => {
        const { isProcessed = false, incrementId } = instance.props;
        const { checkoutStep } = instance.state;

        if (isProcessed && checkoutStep !== DETAILS_STEP) {
            instance.setState({
                isLoading: false,
                paymentTotals: {},
                checkoutStep: DETAILS_STEP,
                orderID: incrementId
            });
        }
    };

    // eslint-disable-next-line no-unused-vars
    aroundSavePaymentMethodAndPlaceOrder = async (args, callback = () => {}, instance) => {
        const { paymentMethodData: { method, additional_data }, component } = args[0];
        console.log(args[0]);
        const guest_cart_id = getGuestQuoteId();

        const paymentMethod = method === ADYEN_CC ? {
            "code": method,
            "adyen_additional_data_cc": {
                cc_type: additional_data.cc_type,
                stateData: additional_data.stateData
            }
        } : {
            "code": method,
            "adyen_additional_data_hpp": {
                brand_code: additional_data.brand_code,
                stateData: additional_data.stateData
            }
        }

        try {
            const query = `mutation (
                $input_1: S_SetPaymentMethodOnCartInput!
                $cartId: String!
            ) {
                s_setPaymentMethodOnCart(
                    input: $input_1
                ) {
                    cart {
                        selected_payment_method {
                            code
                            title
                        }
                    }
                }

                placeOrder (
                        input: {
                            cart_id: $cartId
                        }
                    ) {
                    order {
                        order_id
                        adyen_payment_status {
                            isFinal
                            resultCode
                            additionalData
                            action
                        }
                    }
                }
            }`;

            const variables = {
                "cartId": guest_cart_id,
                "input_1": {
                    "guest_cart_id": guest_cart_id,
                    "payment_method": paymentMethod
                }
            }

            await parseResponse(postFetch(getGraphqlEndpoint(), query, variables)).then((response) => {
                const { placeOrder: { order: { order_id, adyen_payment_status } } } = response;
                BrowserDatabase.setItem(order_id, 'orderId');

                console.log(adyen_payment_status?.isFinal);
                if (adyen_payment_status?.isFinal) {
                    console.log('isFinal');
                    instance.setDetailsStep(order_id);
                    instance.setState({
                        isLoading: false,
                        paymentTotals: {},
                        checkoutStep: DETAILS_STEP,
                        orderID: order_id
                    });
                } else {
                    console.log(adyen_payment_status?.action);
                    if (typeof adyen_payment_status?.action !== undefined && adyen_payment_status?.action !== 'null') {
                        console.log('isUndefined');
                        component.handleAction(JSON.parse(adyen_payment_status.action));
                        console.log('Action après');
                        instance.setState({
                            isLoading: false,
                            orderID: order_id
                        });
                    }
                }
            });
            console.log('Post await parseResponse');
        } catch (e) {
            console.log(e);
            instance._handleError(e);
            instance.setErrorStep(null);
        }
    };

    aroundSetDetailsStep = (args, callback = () => {}, instance) => {
        const { resetCart, resetGuestCart, setNavigationState, updateAdyenState } = instance.props;

        console.log('aroundSetDetailsStep');
        console.log(instance.props);
        console.log(instance.state);
        console.log('---aroundSetDetailsStep');

        // For some reason not logged in user cart preserves qty in it
        if (!isSignedIn()) {
            BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
        }

        BrowserDatabase.deleteItem(PAYMENT_TOTALS);
        BrowserDatabase.setItem(args[0], 'orderId');

        if (isSignedIn()) {
            resetCart();
        } else {
            resetGuestCart();
        }

        const newState = {
            ...(args[1] || {}),
            orderId: args[0],
            incrementId: args[2] || ''
        };

        updateAdyenState(newState);

        setNavigationState({
            name: CART_TAB
        });

        console.log('aroundSetDetailsStep');
        console.log(instance.props);
        console.log(instance.state);
        console.log(newState);
        console.log('---aroundSetDetailsStep');

        if (typeof args[0] !== 'undefined' || args[0] !== '') {
            instance.setState({
                isLoading: false,
                paymentTotals: {},
                checkoutStep: DETAILS_STEP,
                orderID: args[0]
            });
        }
    };
}

const {
    aroundSavePaymentMethodAndPlaceOrder,
    aroundSetDetailsStep,
    aroundComponentDidUpdate
} = new CheckoutContainerPlugin();

export const config = {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder: aroundSavePaymentMethodAndPlaceOrder,
            setDetailsStep: aroundSetDetailsStep,
            componentDidUpdate: aroundComponentDidUpdate
        }
    }
};

export default config;
