export const GET_ACM_CONTENTS = 'GET_ACM_CONTENTS';

/**
 * get ACM content.
 * @param  {Array} acm ACM content from fetch
 * @return {void}
 * @namespace Scandipwa/Store/ACM/Action/getACMContents
 */
export const getACMContents = (acm) => ({
    type: GET_ACM_CONTENTS,
    acm
});
