/* eslint-disable */

import {
    APPEND_PAGE,
    EMPTY_ITEMS,
    UPDATE_LOAD_STATUS,
    UPDATE_PAGE_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_ITEMS
} from 'Store/ProductList/ProductList.action';
import { getIndexedProducts } from 'Util/Product';

/** @namespace Scandipwa/Store/ProductList/Reducer/getInitialState */
export const getInitialState = () => ({
    pages: {},
    totalItems: 0,
    totalPages: 0,
    isLoading: true,
    currentArgs: {}
});

export const defaultConfig = {
    itemsPerPageCount: 24
};

/** @namespace Scandipwa/Store/ProductList/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        items: initialItems = [],
        total_pages: totalPages,
        total_count: totalItems,
        currentPage,
        isLoading,
        args: currentArgs
    } = action;

    let formattedInitialItems = [];

    switch (type) {
    case APPEND_PAGE:
        formattedInitialItems = initialItems.map((product) => {
            return {
                ...product,
                url: `${product.url}?page=${currentPage}`,
                previousPage: `${currentPage}`,
                customFilters: currentArgs?.filter?.customFilters
            }
        });

        return {
            ...state,
            isPageLoading: false,
            pages: {
                ...state.pages,
                [currentPage]: getIndexedProducts(formattedInitialItems)
            }
        };

    case EMPTY_ITEMS:
        return {
            ...state,
            pages: {},
            totalItems: 0,
            totalPages: 0,
            isLoading: true,
            currentArgs: {}
        };

    case UPDATE_PRODUCT_LIST_ITEMS:
    formattedInitialItems = initialItems.map((product) => {
        return {
            ...product,
            url: `${product.url}?page=${currentPage}`,
            previousPage: `${currentPage}`,
            customFilters: currentArgs?.filter?.customFilters
        }
    });

    return {
        ...state,
        currentArgs,
        isLoading: false,
        totalItems,
        totalPages,
        pages: { [currentPage]: getIndexedProducts(formattedInitialItems) }
    };

    case UPDATE_PAGE_LOAD_STATUS:
        return {
            ...state,
            isPageLoading: true
        };

    case UPDATE_LOAD_STATUS:
        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default ProductListReducer;
