/* eslint-disable */

import Adyen from '../component/Adyen';
import CheckoutPayment from "../../scandi-src/src/component/CheckoutPayment";

export const ADYEN_CC = 'adyen_cc';
export const ADYEN_HPP = 'adyen_hpp';

export class CheckoutPaymentsPlugin {
    aroundPaymentRenderMap = (originalMember) => ({
        ...originalMember,
        [ADYEN_CC]: this.renderAdyen.bind(this),
        [ADYEN_HPP]: this.renderAdyenHPP.bind(this)
    });

    renderAdyen(props) {
        const {
            paymentMethodConfig: config = {},
            setPaymentMethodData,
            setPaymentDetailData
        } = props;

        return (
            <Adyen
              config={ config }
              method_code={ ADYEN_CC }
              setPaymentMethodData={ setPaymentMethodData }
              setPaymentDetailData={ setPaymentDetailData }
            />
        );
    }

    renderAdyenHPP(props) {
        const {
            paymentMethodConfig: config = {},
            setPaymentMethodData,
            adyenPaymentMethods,
            setPaymentDetailData
        } = props;

        return (
            <Adyen
                config={ config }
                method_code={ ADYEN_HPP }
                setPaymentMethodData={ setPaymentMethodData }
                setPaymentDetailData={ setPaymentDetailData }
            />
        );
    }

    // eslint-disable-next-line no-unused-vars
    aroundRenderSelectedPayment = (args, callback = () => {}, instance) => {
        const { selectedPaymentCode } = instance.props;
        const render = instance.paymentRenderMap[selectedPaymentCode];

        if (!render) {
            return null;
        }

        return render(instance.props);
    };

    // eslint-disable-next-line no-unused-vars
    aroundRenderPayment = (args, callback = () => {}, instance) => {
        const {
            isAdyenLoaded,
            adyenPaymentMethods
        } = instance.props;

        const method = args[0];
        const { code } = method;

        if ((code === ADYEN_CC || code === ADYEN_HPP) && !isAdyenLoaded) {
            return;
        }

        if (code === ADYEN_HPP) {
            const {
                selectPaymentMethod,
                selectedPaymentCode
            } = instance.props;

            const { code } = method;

            return adyenPaymentMethods.filter(({ type }) => type !== 'scheme').map((adyenPaymentMethod) => {
                const adyenMethod = {
                    code: code,
                    title: adyenPaymentMethod.type,
                    type: adyenPaymentMethod.type
                };

                const isSelected = selectedPaymentCode === `${code}_${adyenMethod.title}`;

                return <CheckoutPayment
                    key={ `${code}_${adyenMethod.title}` }
                    isSelected={ isSelected }
                    method={ adyenMethod }
                    onClick={ selectPaymentMethod }
                />;
            });
        }

        // eslint-disable-next-line consistent-return
        return (
            callback(...args)
        );
    };
}

const {
    aroundPaymentRenderMap,
    aroundRenderPayment,
    aroundRenderSelectedPayment
} = new CheckoutPaymentsPlugin();

export const config = {
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap: aroundPaymentRenderMap
        },
        'member-function': {
            renderSelectedPayment: aroundRenderSelectedPayment,
            renderPayment: aroundRenderPayment
        }
    }
};

export default config;
