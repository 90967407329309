/* eslint-disable */

import AdyenDispatcher from '../store/Adyen/Adyen.dispatcher';

export class CheckoutState {
    mapDispatchToProps = (args, callback, instance) => {
        const [dispatch] = args;

        return {
            ...callback.apply(instance, args),
            updateAdyenState: (payload) => AdyenDispatcher.updateAdyenState(dispatch, payload)
        };
    };

    mapStateToProps = (args, callback, instance) => {
        const [state] = args;

        return {
            ...callback.apply(instance, args),
            isProcessed: state.AdyenReducer.isProcessed,
            incrementId: state.AdyenReducer.incrementId
        };
    };
}

export const { mapDispatchToProps, mapStateToProps } = new CheckoutState();

const config = {
    'Route/Checkout/Container/mapDispatchToProps': {
        function: [
            {
                position: 10,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Route/Checkout/Container/mapStateToProps': {
        function: [
            {
                position: 100,
                implementation: mapStateToProps
            }
        ]
    }
};

export default config;
