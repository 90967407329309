/* eslint-disable */

import {
    BRAINTREE,
    KLARNA
} from 'Component/CheckoutPayments/CheckoutPayments.config';

import { ADYEN_CC, ADYEN_HPP } from './CheckoutPayments.plugin';

export class CheckoutBillingContainerPlugin {
    _aroundGetPaymentData = (args, callback = () => {}, instance) => {
        const { asyncData } = args;
        const { paymentMethod: code } = instance.state;

        callback.apply(instance, args);

        return { code };
    };

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        setPaymentDetailData(data) {
            console.log(data);
            instance.setState({ ...instance.state, data });
        }
    });
}

const {
    _aroundGetPaymentData,
    containerFunctions
} = new CheckoutBillingContainerPlugin();

export const config = {
    'Component/CheckoutBilling/Container': {
        'member-property': {
            containerFunctions
        },
        'member-function': {
            _getPaymentData: _aroundGetPaymentData
        }
    }
};

export default config;
