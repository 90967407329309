import FIELD_TYPE from '@scandi-src/src/component/Field/Field.config';
import Field from '@scandi-src/src/component/Field/Field.container';

import { CheckoutPayment as SourceCheckoutPayment } from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';

import './CheckoutPayment.style.scss';

/** @namespace Scandipwa/Component/CheckoutPayment/Component/CheckoutPayment */
export class CheckoutPayment extends SourceCheckoutPayment {
    render() {
        const {
            isSelected,
            method: { title }
        } = this.props;

        // disable checkbox in order to skip direct clicks on checkbox and handle clicks on entire button instead
        return (
            <li block="CheckoutPayment">
                <button
                  block="CheckoutPayment"
                  mods={ { isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ this.onClick }
                >
                    <Field
                      type={ FIELD_TYPE.radio }
                      attr={ {
                          id: `option-${ title }`,
                          name: `option-${ title }`,
                          checked: isSelected
                      } }
                      label={ title }
                      isDisabled={ false }
                    />
                </button>
            </li>
        );
    }
}

export default CheckoutPayment;
