/* eslint-disable */
import { PureComponent } from 'react';
import loadingSrc from 'style/icons/loading-white.gif';
import './Standby.style.extension.scss';

/** @namespace Scandipwa/Component/Standby/Component/StandbyComponent */
export class StandbyComponent extends PureComponent {
    render() {
        const { isError } = this.props;

        return (
            <div block="Standby">
                <div block="message">
                    { (isError) ? (
                        <>
                            <p block="title">{ __('A problem occurred during the update') }</p>
                            <p block="subtitle">{ __('Please try again later...') }</p>
                        </>
                    )
                    : (
                        <>
                            <div className="loading-icon"><img src={loadingSrc}/></div>
                            <p block="title">{ __('Update in progress') }</p>
                            <p block="subtitle">{ __('Please wait a moment...') }</p>
                        </>
                    ) }
                </div>
            </div>
        );
    }
}

export default StandbyComponent;
