/**
 * Copyright @MarcGlug
 */

export const GET_PICKUP_POINTS = 'GET_PICKUP_POINTS';

/**
 * Get pickup points
 * @param pickups
 * @returns {{pickups, type: string}}
 * @namespace Scandipwa/Store/PickUpPoint/Action/getPickupPoints
 */
export const getPickupPoints = (pickups) => ({
    type: GET_PICKUP_POINTS,
    pickups
});
