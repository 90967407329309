export const GET_STORE_LOCATOR_DATA = 'GET_STORE_LOCATOR_DATA';
export const GET_STORE_LOCATOR_RESULT = 'GET_STORE_LOCATOR_RESULT';

/**
 * Get store locator data
 * @param stores
 * @returns {{type: string, products}}
 * @namespace Scandipwa/Store/StoreLocator/Action/getStoreLocatorData
 */
export const getStoreLocatorData = (stores) => ({
    type: GET_STORE_LOCATOR_DATA,
    stores
});

/**
 * Get store locator result
 * @param storeResult
 * @returns {{type: string, products}}
 * @namespace Scandipwa/Store/StoreLocator/Action/getStoreLocationResult
 */
export const getStoreLocationResult = (storeResult) => ({
    type: GET_STORE_LOCATOR_RESULT,
    storeResult
});
