/**
 * Copyright @MarcGlug
 */

export const GET_RELATED_PRODUCTS = 'GET_RELATED_PRODUCTS';
export const GET_RELATED_PRODUCTS_DATA = 'GET_RELATED_PRODUCTS_DATA';

/**
 * Get related products
 * @param products
 * @returns {{type: string, products}}
 * @namespace Scandipwa/Store/RelatedProducts/Action/getRelatedProducts
 */
export const getRelatedProducts = (products) => ({
    type: GET_RELATED_PRODUCTS,
    products
});

/**
 * Get related products
 * @param productsData
 * @returns {{type: string, products}}
 * @namespace Scandipwa/Store/RelatedProducts/Action/getRelatedProductsData
 */
export const getRelatedProductsData = (productsData, blockInfo) => ({
    type: GET_RELATED_PRODUCTS_DATA,
    payload: { productData: productsData, blockInfo }
});
