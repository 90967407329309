/* eslint-disable */

import CheckoutPayments from "../../scandi-src/src/component/CheckoutPayments/CheckoutPayments.container";

export class CheckoutBillingPlugin {
    // eslint-disable-next-line no-unused-vars
    aroundRenderPayments = (args, callback = () => {}, instance) => {
        const {
            paymentMethods,
            onPaymentMethodSelect,
            setLoading,
            setDetailsStep,
            shippingAddress,
            setPaymentDetailData
        } = instance.props;

        if (!paymentMethods.length) {
            return null;
        }

        return (
            <CheckoutPayments
                setLoading={ setLoading }
                setDetailsStep={ setDetailsStep }
                paymentMethods={ paymentMethods }
                onPaymentMethodSelect={ onPaymentMethodSelect }
                setOrderButtonVisibility={ instance.setOrderButtonVisibility }
                setPaymentDetailData={ setPaymentDetailData }
                billingAddress={ shippingAddress }
                setOrderButtonEnableStatus={ instance.setOrderButtonEnableStatus }
            />
        );
    };
}

const {
    aroundRenderPayments
} = new CheckoutBillingPlugin();

export const config = {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            renderPayments: aroundRenderPayments
        }
    }
};

export default config;
