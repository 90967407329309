/* eslint-disable */

export const ADYEN_CC = 'adyen_cc';
export const ADYEN_HPP = 'adyen_hpp';

export class CheckoutPaymentPlugin {
    // eslint-disable-next-line no-unused-vars
    aroundRender = (args, callback = () => {}, instance) => {
        // eslint-disable-next-line consistent-return
        return (
            callback(...args)
        );
    };
}

const {
    aroundRender
} = new CheckoutPaymentPlugin();

export const config = {
    'Component/CheckoutPayment/Component': {
        'member-function': {
            render: aroundRender
        }
    }
};

export default config;
