import { CheckoutPayments as SourceCheckoutPayments } from 'SourceComponent/CheckoutPayments/CheckoutPayments.component';

import './CheckoutPayments.style.scss';

/**
 * @namespace Scandipwa/Component/CheckoutPayments/Component/CheckoutPayments
 */
export class CheckoutPayments extends SourceCheckoutPayments {
    renderContent() {
        const { hasError } = this.state;

        if (hasError) {
            return (
                <p>{ __('The error occurred during initializing payment methods. Please try again later!') }</p>
            );
        }

        return (
            <>
                <ul block="CheckoutPayments" elem="Methods">
                    { this.renderPayments() }
                </ul>
                { this.renderSelectedPayment() }
            </>
        );
    }
}

export default CheckoutPayments;
