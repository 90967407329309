import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.extension.style.scss';

/** @namespace Scandipwa/Component/FieldSelect/Component/FieldSelectComponent */
export class FieldSelectComponent extends SourceFieldSelect {

}

export default FieldSelectComponent;
