/* eslint-disable */

import { Field } from 'Util/Query';

export class CheckoutQueryPlugin {
    /**
     * Get blog posts per page into config query
     * @returns [Field]
     */
    _aroundGetOrderField = (args, callback, instance) => new Field('order')
        .addFieldList([
            'id',
            'order_id',
            'redirectUrl',
            'threeDS2',
            'type',
            'token'
        ]);
}

export const { _aroundGetOrderField } = new CheckoutQueryPlugin();

export const config = {
    'Query/Checkout': {
        'member-function': {
            _getOrderField: _aroundGetOrderField
        }
    }
};

export default config;
