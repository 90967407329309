/* eslint-disable */
import { GET_PICKUP_POINTS } from './PickUpPoint.action';

/** @namespace Store/PickUpPoint/Reducer/getInitialState */
export const getInitialState = () => ({
    pickups: {}
});

/** @namespace Store/PickUpPoint/Reducer/PickUpPointReducer */
export const PickUpPointsReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        case GET_PICKUP_POINTS:
            const { pickups } = action;

            return {
                ...state,
                pickups: pickups
            };

        default:
            return state;
    }
};

export default PickUpPointsReducer;
